<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Experiment 2 - Pre-Lab Exercise</h2>

      <p class="mb-n3">
        a) Which of the following mixtures could NOT be separated into its components by decanting?
      </p>

      <v-radio-group
        v-model="inputs.separatedByDecanting"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in optionsSeparatedByDecanting"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        b) If I pour the water off after I boil the potatoes, what is this an example of? Note: I am
        separating the solid potatoes from the liquid hot water.
      </p>

      <v-radio-group v-model="inputs.nameTheProcess" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsNameTheProcess"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">c) Which of these are methods of separating a solid from a liquid?</p>

      <v-radio-group
        v-model="inputs.separateSolidFromLiquid"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in optionsSeparateSolidFromLiquid"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        d) In the process of evaporation, which one of the following pieces of laboratory apparatus
        would NOT be used?
      </p>

      <v-radio-group v-model="inputs.evaporation" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsEvaporation"
          :key="'pt-4-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        e) Sodium chloride can be separated from rocks by first adding water to the mixture to
        dissolve the sodium chloride. The separation then takes place in two stages:
      </p>

      <v-radio-group
        v-model="inputs.separatingSaltAndRocks"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in optionsSeparatingSaltAndRocks"
          :key="'pt-5-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        f) Two or more substances mingled together, but not chemically combined are known as a
        _________:
      </p>

      <v-radio-group v-model="inputs.mingledTogether" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsMingledTogether"
          :key="'pt-6-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        g) Which of the following would you use to separate sand from iron filings?
      </p>

      <v-radio-group
        v-model="inputs.separatingSandFromIron"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in optionsSeparatingSandFromIron"
          :key="'pt-7-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'OleMissGlasswareQuestions',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        separatedByDecanting: null,
        nameTheProcess: null,
        separateSolidFromLiquid: null,
        evaporation: null,
        separatingSaltAndRocks: null,
        mingledTogether: null,
        separatingSandFromIron: null,
      },
      optionsSeparatedByDecanting: [
        {text: 'Sand in water', value: 'sandWater'},
        {text: 'Beans in hot water', value: 'beansWater'},
        {text: 'Coffee in hot water', value: 'coffeeWater'},
        {text: 'All other responses', value: 'allA'},
      ],
      optionsNameTheProcess: [
        {text: 'Decoupage', value: 'decoupage'},
        {text: 'Sublimation', value: 'sublimation'},
        {text: 'Decanting', value: 'decanting'},
        {text: 'Dissolving', value: 'dissolving'},
      ],
      optionsSeparateSolidFromLiquid: [
        {text: 'Decanting, filtering, and dissolving', value: 'decantingFilteringDissolving'},
        {text: 'Decanting, filtering, and evaporating', value: 'decantingFilteringEvaporating'},
        {text: 'Decanting and dissolving', value: 'decantingDissolving'},
        {text: 'All other responses', value: 'allC'},
      ],
      optionsEvaporation: [
        {text: 'Bunsen burner', value: 'bunsenBurner'},
        {text: 'Wire gauze', value: 'wireGauze'},
        {text: 'Evaporating dish', value: 'evaporatingDish'},
        {text: 'Filter funnel', value: 'filterFunnel'},
      ],
      optionsSeparatingSaltAndRocks: [
        {text: 'Filtration followed by evaporation', value: 'filtrationEvaporation'},
        {text: 'Evaporation followed by filtration', value: 'evaporationFiltration'},
        {text: 'Filtration followed by decanting', value: 'filtrationDecanting'},
        {text: 'Distillation followed by decanting', value: 'distillationDecanting'},
      ],
      optionsMingledTogether: [
        {text: 'Mixture', value: 'mixture'},
        {text: 'Residue', value: 'residue'},
        {text: 'Solution', value: 'solution'},
        {text: 'Distillate', value: 'distillate'},
      ],
      optionsSeparatingSandFromIron: [
        {text: 'A distillation apparatus', value: 'distillationApparatus'},
        {text: 'A bar magnet', value: 'barMagnet'},
        {text: 'Filter paper', value: 'filterPaper'},
        {text: 'Chromatography paper', value: 'chromatographyPaper'},
      ],
    };
  },
};
</script>
<style scoped></style>
